<template lang="pug">
div.w-100.text-left.white
  h4.text-left.pt-2 {{ $t('report') }}
  v-tabs(
    show-arrows
    next-icon="mdi-arrow-right-bold-box-outline"
    prev-icon="mdi-arrow-left-bold-box-outline")
    v-tabs-slider(color="primary")
    div.d-flex.align-items-center(v-for="tab in tabs" :key="tab.id")
      v-tab(:to="tab.link" v-if="tab.checkAccess()").p-2 {{$t(tab.label)}}
  router-view

</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      tabs: [
        { id: 1, label: 'sqc', link: { name: 'sqc-report-protocol' }, checkAccess: () => checkAccess('sqc-report') },
        { id: 2, label: 'student', link: { name: 'cadet-report' }, checkAccess: () => checkAccess('cadet-report') },
        { id: 3, label: 'education', link: { name: 'education-report-2' }, checkAccess: () => checkAccess('education-report') },
        { id: 4, label: 'qualificationDocsTab', link: { name: 'qualification-report' }, checkAccess: () => checkAccess('qualification-report') },
        { id: 5, label: 'eti', link: { name: 'certificates-report-2' }, checkAccess: () => checkAccess('certificates-report') },
        { id: 6, label: 'sailorPassportShort', link: { name: 'sailor-passport-report' }, checkAccess: () => checkAccess('sailorPassport-report') },
        { id: 7, label: 'financial', link: { name: 'financial-report-2' }, checkAccess: () => checkAccess('financial-report') },
        { id: 8, label: 'exсelReports', link: { name: 'excel-report' }, checkAccess: () => checkAccess('excel-report') },
        { id: 9, label: 'debtor', link: { name: 'debtor-report' }, checkAccess: () => checkAccess('admin') },
        { id: 10, label: 'distribution', link: { name: 'distribution-report-crewing' }, checkAccess: () => checkAccess('admin') },
        { id: 11, label: 'submittedDocuments', link: { name: 'submitted-documents-report' }, checkAccess: () => checkAccess('submitted-documents-report') },
        { id: 12, label: 'crewManagerAndSailor', link: { name: 'crewing-connect-company-report' }, checkAccess: () => checkAccess('crewing-sailor-report') },
        { id: 13, label: 'atc', link: { name: 'atc-distribution-institution-report' }, checkAccess: () => checkAccess('distribution-report') },
        { id: 14, label: 'certificate-for-members-of-the-sqc-report', link: { name: 'certificate-for-members-of-the-sqc-report' }, checkAccess: () => checkAccess('certificate-for-members-of-the-sqc-report') },
        { id: 15, label: 'service-record-book-report', link: { name: 'service-record-book-report' }, checkAccess: () => checkAccess('service-record-book-report') },
        { id: 16, label: 'statement-iso-report', link: { name: 'statement-connect-isc-report' }, checkAccess: () => checkAccess('backOffice') },
        { id: 17, label: 'medical', link: { name: 'medical-report' }, checkAccess: () => checkAccess('backOffice') },
        { id: 18, label: 'replacement-qualification', link: { name: 'replacement-qualification-report' }, checkAccess: () => checkAccess('statement_replace_qualification', 'report') },
        { id: 19, label: 'crewing-plus-connect-sailor', link: { name: 'crewing-plus-connect-sailor-report' }, checkAccess: () => checkAccess('backOffice') },
        { id: 20, label: 'statement-position-isc', link: { name: 'statement-position-isc-report' }, checkAccess: () => checkAccess('backOffice') },
        { id: 21, label: 'powersOfAttorney', link: { name: 'powers-of-attorney-report' }, checkAccess: () => checkAccess('backOffice') },
        { id: 22, label: 'nostrification', link: { name: 'nostrification-report' }, checkAccess: () => checkAccess('backOffice') },
        { id: 23, label: 'document-digitization-isc', link: { name: 'document-digitization-isc' }, checkAccess: () => checkAccess('backOffice') }
      ]
    }
  },
  beforeMount () {
    this.$store.commit('setActivePage', { name: 'report', access: checkAccess('menuItem-documentsVerification') })
    this.gdirection()
  },
  methods: {
    ...mapActions(['gdirection'])
  }
}
</script>
